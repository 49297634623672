import Vue from 'vue'
import App from './App.vue'
import router from './router'
//import './index.css'

Vue.config.productionTip = false

Vue.filter("dateFormat", function(value: string) {
  if (!value) return "";
  try {
    let date = new Date(value);
    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear().toString().substr(-2);
    if (date.getFullYear() < 1932) {
      year = date.getFullYear().toString();
    }
    return `${day}.${month}.${year}`;
  } catch (e) {
    return value;
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
