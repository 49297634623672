import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Song Search - Get Matrix',
    component: () => import(/* webpackChunkName: "about" */ '../views/SongSearch.vue')
  },
  {
    path: '/artist',
    name: 'Artist lookup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ArtistSearch.vue')
  },
  {
    path: '/label',
    name: 'Label lookup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LabelSearch.vue')
  },
  {
    path: '/youtube',
    name: 'Youtube Search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/YoutubeSearch.vue')
  },
  {
    path: '/playlist',
    name: 'Playlist Search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PlaylistSearch.vue')
  },
  {
    path: '/heritage-search',
    name: 'Heritage reference',
    component: () => import('../views/HeritageSearch.vue')
  },
  {
    path: '/german-search',
    name: 'German Musiktitel DB Search',
    component: () => import('../views/GermanSearch.vue')
  },
  {
    path: '/kellys-search',
    name: 'Kelly\'s DB',
    component: () => import('../views/GramophoneSearch.vue')
  },
  {
    path: '/mp3-downloader',
    name: 'Grabber Mp3',
    component: () => import('../views/Mp3Downloader.vue')
  }
]

const router = new VueRouter({
  routes
})

const DEFAULT_TITLE = 'Some Default Title';
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.name || DEFAULT_TITLE;
    });
});

export default router
